import React, { Component, useState, useEffect, Suspense } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea } from '../../components';
import css from './FieldTextInput.module.css';
import {
  Tooltip,
} from 'react-tippy';
import { ReactComponent as HelpIcon } from '../../assets/help.svg';

const ReactQuill = React.lazy(() => import('react-quill'));
const CONTENT_MAX_LENGTH = 5000;

const FieldTextInputComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    hideErrorMessage,
    textValue,
    help,
    ...rest
  } = props;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Only run this effect on the client (when document is available)
    setIsClient(true);
  }, []);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const isTextarea = input.type === 'textarea';
  const isWYSIWYG = input.type === 'wysiwyg'; // Check if input type is WYSIWYG
  const isTextOnly = input.type === 'textOnly';

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Textarea doesn't need type.
  const { type, ...inputWithoutType } = input;
  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
      [css.textarea]: isTextarea,
    });
  const maxLength = CONTENT_MAX_LENGTH;
  const inputProps = isTextarea
    ? {
        className: inputClasses,
        id,
        rows: 1,
        maxLength,
        ...refMaybe,
        ...inputWithoutType,
        ...rest,
      }
    : isWYSIWYG
    ? {
        value: input.value, // ReactQuill is controlled, pass the value
        onChange: (content, delta, source, editor) => input.onChange(content), // Update form value on content change
        ...rest,
        ...input,
        className: inputClasses,
        id,
        type,
        ...refMaybe,
      }
    : isUncontrolled
    ? {
        className: inputClasses,
        id,
        type,
        defaultValue,
        ...refMaybe,
        ...inputWithoutValue,
        ...rest,
      }
    : { className: inputClasses, id, type, ...refMaybe, ...input, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  const helloClass = classNames(css.hello);
  const tinyClass = classNames(css.myTippy);

  return (
    <div className={classes}>
      {label ? 
        <div style={{display:'flex', alignItems:'baseline'}}>
          <label htmlFor={id}>{label}</label>
          {help&&<Tooltip
            // options
            html={(
              // <div style={{textAlign:'left', fontSize:'10px'}} dangerouslySetInnerHTML={{__html: help}}></div>
              <div className={helloClass} dangerouslySetInnerHTML={{__html: help}}></div>
            )}
            position="bottom"
            trigger="click"
            arrow={true}
            className={tinyClass}
          >
            <div style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x-circle"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="12" r="9" />  
                <line x1="12" y1="17" x2="12" y2="17.01" />  
                <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
              </svg>
            </div>
            {/* <span style={{fontSize:'13px', marginLeft:'10px'}}>hello</span> */}
          </Tooltip>}
        </div> : null
      }
      {isWYSIWYG && isClient ? (
        <Suspense fallback={<div>Loading editor...</div>}>
          <ReactQuill
            {...inputProps}
            theme="snow"
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['bold', 'italic', 'underline', 'strike'],
                ['link', 'blockquote', 'code-block', 'image'],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'font',
              'list',
              'bullet',
              'indent',
              'bold',
              'italic',
              'underline',
              'strike',
              'link',
              'blockquote',
              'code-block',
              'image',
              'color',
              'background',
              'align',
            ]}
          />
        </Suspense>
      ) : isTextarea ? (
        <ExpandingTextarea {...inputProps} />
      ) : isTextOnly?(<span style={{fontSize:'13px', marginBottom: '10px', color: '#837e7e'}}>{textValue}</span>):(
        <input {...inputProps} />
      )}
      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextInputComponent} {...this.props} />;
  }
}

export default FieldTextInput;
